import React from 'react';
import Modal from '../../../../components/common/ModalNew/index'
import Button from '../../../../components/common/Button/button'
import { Label } from '../../../../components/common/Input/input.style'
import Tabs from '../../../../components/common/Tabs/tabs';
import { useEffect, useState } from 'react';
import { useStore } from '../../../../mobx-store/context';
import Tab from './Tab';
import { getDescription, modifyDescription } from '../../../../helpers/helpers';
import { api } from '../../../../api';
import { Spin } from 'antd';
const IaAndRenewalsInfoModal = ({
  onClose,
  title,
  titleStyle,
  licenseCompanyId,
}) => {

  const store = useStore()
  const [initialData, setInitialData] = useState(null)
  const [renewalsData, setRenewalsData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [activeTab, setActiveTab] = useState('initial_application')

  const mainTabs = [
    { key: 'initial_application', title: 'Initial Application' },
    { key: 'renewals', title: 'Renewals' },
  ]

  const getData = (licenseCompanyId, type) => {
    setLoading(true)
    const url = `/license_company/${licenseCompanyId}/additional_modals_information/?for=${type}`
    api(url, {}, 'GET').then((res) => (
      type === 'initial' ? setInitialData(res) : setRenewalsData(res)
    )).finally(() => setLoading(false)
    )
  }

  useEffect(() => {
    setLoading(true)
    activeTab === 'initial_application'
      ? getData(licenseCompanyId, 'initial')
      : getData(licenseCompanyId, 'renewals')
  }, [activeTab])

  const showMainTabs = () => {
    return {
      initial_application: {
        render: () => initialData
          ? <Tab type={'initial'} references={references} data={initialData} mainActiveTab={activeTab} />
          : <div style={{ width: 600, height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Spin />
          </div>
      },
      renewals: {
        render: () => renewalsData ? <Tab type={'renewals'} references={references} data={renewalsData} mainActiveTab={activeTab} />
          : <div style={{ width: 600, height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Spin />
          </div>
      },
    }
  }
  const references = [
    {
      key: 1,
      title: 'Jurisdiction',
      description: getDescription(store.license_source_pending_jurisdictions, store?.currentDetermination?.license_determination?.jurisdiction),
    },
    {
      key: 2,
      title: 'License Type',
      description: getDescription(store.license_pending_types, store?.currentDetermination?.license_determination?.license_type, 'name'),
    },
    {
      key: 3,
      title: 'Line of Business',
      description: modifyDescription(store.license_source_pending_line_of_business, store?.currentDetermination?.license_determination?.line_of_business)
    }
  ]

  //  const showHeldMaterialsTabs = ({ data, setVisibleHeldAddModal, setHeldType, trigger, setTrigger, determinationId, historyMode, heldHistory = {} }) => {
  //     return {
  //       admin_actions: {
  //         render: () => <HeldMaterials
  //           liceneCompanyId={data?.id}
  //           materialType={'admin_actions'}
  //           trigger={trigger}
  //           setTrigger={setTrigger}
  //           historyMode={historyMode}
  //           historyData={heldHistory}
  //           onAddNew={() => {
  //             setHeldType('admin_actions')
  //             setVisibleHeldAddModal(true)
  //           }}
  //         />
  //       },
  //       notice_of_change_held: {
  //         render: () => <HeldMaterials
  //           liceneCompanyId={data?.id}
  //           materialType={'notice_of_change_held'}
  //           trigger={trigger}
  //           historyMode={historyMode}
  //           historyData={heldHistory}
  //           setTrigger={setTrigger}
  //           determinationId={determinationId}
  //           onAddNew={() => {
  //             setHeldType('notice_of_change_held')
  //             setVisibleHeldAddModal(true)
  //           }}
  //         />
  //       },
  //       market_conduct_exams_held: {
  //         render: () => <HeldMaterials
  //           liceneCompanyId={data?.id}
  //           historyMode={historyMode}
  //           historyData={heldHistory}
  //           materialType={'market_conduct_exams_held'}
  //           trigger={trigger}
  //           setTrigger={setTrigger}
  //           determinationId={determinationId}
  //           onAddNew={() => {
  //             setHeldType('market_conduct_exams_held')
  //             setVisibleHeldAddModal(true)
  //           }}
  //         />
  //       },
  //       misc_held: {
  //         render: () => <HeldMaterials
  //           liceneCompanyId={data?.id}
  //           materialType={'misc_held'}
  //           historyMode={historyMode}
  //           historyData={heldHistory}
  //           determinationId={determinationId}
  //           trigger={trigger}
  //           setTrigger={setTrigger}
  //           onAddNew={() => {
  //             setHeldType('misc_held')
  //             setVisibleHeldAddModal(true)
  //           }}
  //         />
  //       },
  //     }
  //   }

  return (
    <Modal
      styleWrap={{
        width: 'auto',
        minWidth: 860,
        maxWidth: 800,
        height: '75%',
        minHeight: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
      onClose={onClose}>
      {title && <Label style={titleStyle}>{title}</Label>}
      <Tabs
        Reporting={false}
        tabs={mainTabs}
        onClick={(key) => {
          setActiveTab(key)
        }}
        active={activeTab}
      />

      {showMainTabs()[activeTab].render()}

    </Modal>
  )
}

export default IaAndRenewalsInfoModal
