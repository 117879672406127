import { useState, useEffect } from 'react';
import { Row, Block, Label, Text, EmptyBlock } from '../right.style';
import { Block as MoreInfoBlock, Item } from '../MoreInfo/moreInfo.style';
import Tabs from '../../../../components/common/Tabs/tabs';
import StaticMaterials from './StaticMaterials';
import { Spin } from 'antd';
import { formatCurrency } from '../../../AdminPanel/helpers';
const Tab = ({ data, references, mainActiveTab }) => {
  const [activeTab, setActiveTab] = useState('checklist');
  const tabs = [
    { key: 'checklist', title: 'Checklist' },
    { key: 'forms', title: 'Forms' },
    { key: 'guidance', title: 'Guidance' },
    { key: 'notes', title: 'Notes' },
    { key: 'tips', title: 'Tips' },
    { key: 'citations', title: 'Citations' },
    { key: 'notice_of_change', title: 'Notice of Change' },
    { key: 'confidentiality', title: 'Confidentiality' },
  ];

  useEffect(() => {
    setActiveTab('checklist');
  }, [mainActiveTab]);

  if (!data) return <Spin />;

  const showTabs = () => {
    return {
      checklist: {
        render: () => <Block
          style={{
            marginTop: '10px',
            paddingBottom: '1em',
            minHeight: 120,
            height: '100%',
            maxHeight: 350,
            overflowY: 'auto',
          }}
          bordered
        >
          {data.checklist.length > 0 ? data.checklist.map((item, index) => (
            <div style={{}} key={index}>
              <div
                style={{
                  color: '#225194',
                  fontFamily: 'var(--medium)',
                  fontSize: 14,
                }}>
                {item.text}
              </div>
            </div>
          ))
            : <EmptyBlock style={{ height: '100%', border: 'none' }}>Not Specified.</EmptyBlock>}
        </Block>,
      },
      forms: {
        render: () => <StaticMaterials materials={data.forms[0]} />,
      },
      citations: {
        render: () => <StaticMaterials materials={data.citations[0]} />,
      },
      notes: {
        render: () => <StaticMaterials materials={data.notes[0]} />,
      },
      guidance: {
        render: () => <StaticMaterials materials={data.guidance[0]} />,
      },
      tips: {
        render: () => <StaticMaterials materials={data.tips[0]} />,
      },
      notice_of_change: {
        render: () => <StaticMaterials materials={data.notice_of_change[0]} />,
      },
      confidentiality: {
        render: () => <StaticMaterials materials={data.confidentiality[0]} />,
      },
    };
  };

  return (
    <>
      <Row style={{ marginTop: '1em' }}>
        {references?.map(
          ({ key, title, description, hide }) =>
            !hide && (
              <Block key={key} style={{ maxWidth: 200 }}>
                <Label>{title}</Label>
                <Text dangerouslySetInnerHTML={{ __html: description }} />
              </Block>
            )
        )}
      </Row>
      <MoreInfoBlock visible={true} style={{ marginTop: '1em', marginBottom: '2em' }}>
        {data?.license_term && (
          <Item>
            <span>License Term</span>
            {data?.license_term || 'No Data'}
          </Item>
        )}
        {data?.filling_fee && (
          <Item>
            <span>Filing Fee</span>
            {formatCurrency(data?.filling_fee) || 'No Data'}
          </Item>
        )}
        {data?.submission_method && (
          <Item>
            <span> Subission Method</span>
            {data?.submission_method || 'No Data'}
          </Item>
        )}
        {data?.regulatory_agency && (
          <Item>
            <span>Regulatory Agency</span>
            {data?.regulatory_agency || 'No Data'}
          </Item>
        )}
      </MoreInfoBlock>
      <Tabs
        Reporting={false}
        tabs={tabs}
        onClick={(key) => {
          setActiveTab(key)
        }}
        active={activeTab}
      />
      {showTabs()[activeTab].render()}
    </>
  );
};

export default Tab;