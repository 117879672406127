import React from 'react';
import { Block, DocumentsPlaceholder, EmptyBlock, InfoBlock } from '../right.style';
import Document from '../ReportMaterials/document';

const StaticMaterials = ({ materials }) => {
  if (!materials) return <EmptyBlock style={{ height: '100%' }}>Not Specified.</EmptyBlock>;

  return (
    <Block
      style={{
        marginTop: 0,
        paddingBottom: '1em',
        minHeight: 120,
        height: '100%',
        maxHeight: 350,
        overflowY: 'auto',

      }}
      bordered
    >
      {!materials && (
        <DocumentsPlaceholder style={{ height: '100%' }}>
          There are no documents associated with this item yet.
        </DocumentsPlaceholder>
      )}

      {materials.description && (
        <InfoBlock
          style={{ marginTop: 5, height: '100%' }}
          dangerouslySetInnerHTML={{
            __html: materials.description.replace(/<a /g, '<a target="_blank" '),
          }}
        />
      )}

      {materials.files?.length > 0 &&
        materials.files.map((file) => (
          <Document key={file.id || file.name} file={file} />
        ))}
    </Block>
  );
};

export default StaticMaterials;